import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Status, URL } from "../../../../../Constants/Consts";
import userOnlineCheck from "../../../../../Helpers/CustomHooks/userOnlineCheck";
import { fetchApi } from "../../../../../Helpers/Functions";
import { updateChangeLogs } from "../../../../../store/ChangeLogs";
import { setSelectedSequenceNumber, setSelectedUnitNumber, setSelectedInspectionTask, updateCheckListItem } from "../../../../../store/SequenceObject";
import { UnitChecklistItem } from "../../../../../types/types";
import { RootState } from "../../../../../store/store";

import { toast } from "react-toastify";
import { BiReset } from "react-icons/bi";
import { FaCheck } from "react-icons/fa";
import { FiSlash } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";

export default function CheckListButtons({ item, UnitId, SequenceId, }: {
  item: UnitChecklistItem;
  UnitId: string;
  SequenceId: string;
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const online = userOnlineCheck();

  const hasActivePunchList = (checklistUuid) => {
    const relatedPendingPLs = ProjectPunchLists.filter(pl => pl.checklistUuid === checklistUuid && pl.status === Status.Pending);
    return relatedPendingPLs.length > 0;
  };

  const fetchChangeLogs = async () => {
    if (online) {
      try {
        const logs = await fetchApi(URL.getUpdatedChangeLogsUrl(item.projectNumber, item.disciplineId, item.checklistUuid))
          .then(res => res.json());
        dispatch(updateChangeLogs({ uuidType: "Checklist", uuid: item.checklistUuid, data: logs }));
      } catch (error) {
        console.error('Failed to fetch changelogs:', error);
      }
    }
  };

  const { ProjectPunchLists } = useSelector((state: RootState) => state.PunchList)
  const update = React.useCallback(async (e, status) => {
    e.stopPropagation();
    const Statuses = {
      1: "Open",
      2: "Pass",
      3: "Fail",
      5: "Mark as N/A"
    };
  
    if (hasActivePunchList(item.checklistUuid) && status !== Status.Failed) {
      if (status === Status.NA) {
        toast.error(`Cannot mark this task as N/A until all associated punch list items are completed.`, { type: "error" });
        return;
      }
      toast.error(`Cannot ${Statuses[status]} this task until all associated punch list items are completed.`, { type: "error" });
      return;
    }
  
    const updatedItem = { ...item, status };
    try {
      await fetchApi(URL.updateUnitCheckListUrl(item.checklistUuid), {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(updatedItem),
      })
        .then((res) => res.json())
        .then(() =>
          dispatch(
            updateCheckListItem({
              UnitId,
              SequenceId,
              checklistItem: updatedItem,
            })
          )
        );
    } catch (error) {
      if (!navigator.onLine) {
        dispatch(
          updateCheckListItem({
            UnitId,
            SequenceId,
            checklistItem: { ...updatedItem, notSync: true },
          })
        );
      } else throw error;
    }
  }, [ProjectPunchLists, item, dispatch, UnitId, SequenceId]);
  

  const navigateToPunchList = (item: UnitChecklistItem) => {
    console.log('Dispatching inspection task:', item.inspectionTask);
    dispatch(setSelectedSequenceNumber(String(SequenceId)));
    dispatch(setSelectedUnitNumber(String(UnitId)));
    dispatch(setSelectedInspectionTask(item.inspectionTask));
    navigate(`/PunchList/Form/CheckList/${item.checklistUuid}`);
  };

  return (
    <>
      <div className="flex justify-start pt-8 mb-8 gap-4">
        <div className="flex flex-col items-center">
          <div
            onClick={async (e) => {
              e.stopPropagation();
              await update(e, Status.Passed);

              try {
                await fetchChangeLogs();
              } catch (error) {
                console.log(error);
              }
            }}
            className={`rounded-full p-2 flex justify-center items-center cursor-pointer ${hasActivePunchList(item.checklistUuid) ? 'bg-gray-500 opacity-50' : 'bg-green-500'}`}
          >
            <FaCheck className="text-white" />
          </div>
          <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.checklistUuid) ? 'opacity-50' : ''}`}>
            <p>Mark</p>
            <p>Passed</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div
            onClick={async (e) => {
              e.stopPropagation();
              await update(e, Status.Failed);
              navigateToPunchList(item);

              try {
                if (navigator.onLine) {
                  await fetchChangeLogs();
                }
              } catch (error) {
                console.log(error);
              }
            }}
            className="rounded-full p-2 flex justify-center items-center cursor-pointer bg-red-500">
            <IoMdClose className="text-white" />
          </div>
          <div className="text-center text-xs font-bold mt-1 uppercase">
            <p>Create</p>
            <p>Punch List</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div
            onClick={async (e) => {
              e.stopPropagation();
              await update(e, Status.NA);

              try {
                if (navigator.onLine) {
                  await fetchChangeLogs();
                }
              } catch (error) {
                console.log(error);
              }
            }}
            className={`rounded-full p-2 flex justify-center items-center cursor-pointer ${hasActivePunchList(item.checklistUuid) ? 'bg-gray-500 opacity-50' : 'bg-gray-500'}`}
          >
            <FiSlash className="text-white" />
          </div>
          <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.checklistUuid) ? 'opacity-50' : ''}`}>
            <p>Not</p>
            <p>Applicable</p>
          </div>
        </div>
        <div className="flex flex-col items-center">
          <div
            onClick={async (e) => {
              e.stopPropagation();
              await update(e, Status.Open);

              try {
                if (navigator.onLine) {
                  await fetchChangeLogs();
                }
              } catch (error) {
                console.log(error);
              }
            }}
            className={`rounded-full p-2 flex justify-center items-center cursor-pointer ${hasActivePunchList(item.checklistUuid) ? 'bg-gray-500 opacity-50' : 'bg-gray-500'}`}
          >
            <BiReset className="text-white" />
          </div>
          <div className={`text-center text-xs font-bold mt-1 uppercase ${hasActivePunchList(item.checklistUuid) ? 'opacity-50' : ''}`}>
            <p>Reset</p>
            <p>Status</p>
          </div>
        </div>
      </div>
    </>
  );
}
