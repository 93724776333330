import { useNavigate } from "react-router-dom";
import SectionHeader from "../../components/CoreComponents/Details/SectionHeader.tsx";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import FormDDL from "../../components/CoreComponents/ReactFormComponents/FormDDL.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store.ts";
import FormInput from "../../components/CoreComponents/ReactFormComponents/FormInput.tsx";
import { fetchApi, splitOnCapitals } from "../../Helpers/Functions.ts";
import { useEffect, useState } from "react";
import { mapAdHocUnitToAPI } from "../../Helpers/Mappers.ts";
import { detailsSchema, CreateAdHocUnitItem } from "../../types/Unit/AdHocUnitSchema.ts";
import Loader from "../../components/CoreComponents/Loader.tsx";
import { PiSealWarning } from "react-icons/pi";
import { URL } from "../../Constants/Consts.ts";
import { Discipline, getProjectText } from "../../types/types.ts";
import { twMerge } from "tailwind-merge";

export default function CreateAdHocUnit() {
    const navigate = useNavigate();
    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue
    } = useForm({ resolver: zodResolver(detailsSchema) });

    const { SelectedUser } = useSelector((state: RootState) => state.SelectedUser)
    const { projects } = useSelector((state: RootState) => state.Project)
    const { allDisciplines } = useSelector((state: RootState) => state.Discipline)
    const { ModelItem } = useSelector((state: RootState) => state.Model);

    const [selectedModelNumber, setSelectedModelNumber] = useState<number>();
    const [selectedProject, setSelectedProject] = useState<number>();
    const [filteredDisciplines, setFilteredDisciplines] = useState<Discipline[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchSequences = async (projectNumber: number, disciplineId: number) => {
        const url = `${import.meta.env.VITE_API_URL}/sequence?projectNumber=${projectNumber}&disciplineId=${disciplineId}`;
        const response = await fetchApi(url, { headers: { 'accept': 'application/json' } });
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
    };

    useEffect(() => {
        if (ModelItem[selectedModelNumber]?.length === 1) {
            setValue("ModelFull", ModelItem[selectedModelNumber][0])
        } else {
            setValue("ModelFull", null)
        }
    }, [selectedModelNumber]);

    useEffect(() => {
        setValue("ProjectNumber", SelectedUser.projectNumber)
        setSelectedProject(SelectedUser.projectNumber)
    }, [SelectedUser]);

    useEffect(() => {
        if (selectedProject === undefined) {
            return;
        }
        Promise.all(allDisciplines.map(discipline => {
            return fetchSequences(selectedProject, discipline.id)
                .then(res => {
                    if (res.length !== 0) {
                        return discipline;
                    }

                });
        })).then(results => {
            const filtered = results.filter(discipline => discipline ?? discipline);
            setFilteredDisciplines(filtered);
            setValue("Discipline", undefined)
        })
    }, [selectedProject]);

    const onSubmit = async data => {
        setLoading(true);
        const apiData: CreateAdHocUnitItem = mapAdHocUnitToAPI({
            ...data,
            SequenceNumber: String(data.SequenceNumber),
            UnitNumber: String(data.UnitNumber)
        });
    
        await fetchApi(URL.postAdHocUnitUrl(), {
            method: "POST",
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(apiData)
        }).then(() => navigate(-1))
    
        setLoading(false);
    }

    return (
        loading ? <Loader /> :
            <div className='max-w-screen-md'>
                <div className='mt-8 mb-8'>
                    <SectionHeader title='Add Unit Checklist Item' />
                </div>
                <div className='flex items-center mb-5 '>
                    <PiSealWarning className='mr-2 text-uniteOrange' />
                    <h2>Checklist could take up to 15min to show in UNITE.</h2>
                </div>
                <div>
                    <form className='relative' onSubmit={handleSubmit(onSubmit)}>
                        <FormDDL
                            required={true}
                            options={
                                [...projects]
                                    .sort((a, b) => a.projectName.localeCompare(b.projectName))
                                    .map(i => ({ text: getProjectText(i), value: i.projectNumber }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={SelectedUser.projectNumber}
                            inputKey={"ProjectNumber"}
                            label={"Project Selection"}
                            name={"ProjectNumber"}
                            onChangeEvent={(selected) => {
                                setSelectedProject(selected);
                            }}
                        />
                        <FormDDL
                            required={true}
                            options={
                                [...filteredDisciplines]
                                    .sort((a, b) => a.disciplineName.localeCompare(b.disciplineName))
                                    .map(i => ({ text: i.disciplineName, value: i.id }))
                            }
                            control={control}
                            errors={errors}
                            inputKey={"Discipline"}
                            label={"Discipline Selection"}
                            name={"Discipline"}
                            showErrorIfOptionsEmpty={true}
                            errorTextIfOptionsIsEmpty={'There are no disciplines available for the current project selection.'}
                            defaultValue={undefined}
                        />
                        <FormInput
                            required={true}
                            errors={errors}
                            defaultValue=""
                            inputKey={"SequenceNumber"}
                            registeredProps={register("SequenceNumber")}
                            label={splitOnCapitals("SequenceNumber")}
                            placeholder={''}
                        />
                        <FormInput
                            required={true}
                            errors={errors}
                            defaultValue=""
                            inputKey={"UnitNumber"}
                            registeredProps={register("UnitNumber")}
                            label={splitOnCapitals("UnitNumber")}
                            placeholder={''}
                        />
                        <FormDDL
                            required={true}
                            options={
                                [...Object.keys(ModelItem)]
                                    .sort((a, b) => a.localeCompare(b))
                                    .map(i => ({ text: i, value: i }))
                            }
                            control={control}
                            errors={errors}
                            defaultValue={undefined}
                            inputKey={"ModelNumber"}
                            label={splitOnCapitals("ModelNumber")}
                            name={"ModelNumber"}
                            onChangeEvent={(selected) => {
                                setSelectedModelNumber(selected);
                            }}
                        />
                        <FormDDL
                            required={true}
                            disabled={!selectedModelNumber}
                            options={
                                ModelItem[selectedModelNumber] ?
                                    ModelItem[selectedModelNumber]
                                        .sort((a, b) => a.localeCompare(b))
                                        .map(i => ({ text: i, value: i })) :
                                    []
                            }
                            control={control}
                            errors={errors}
                            defaultValue={undefined}
                            inputKey={"ModelFull"}
                            label={splitOnCapitals("ModelFull")}
                            name={"ModelFull"}
                        />
                        <div className='flex items-center mt-16'>
                            <button
                                className='absolute left-0 mt-3 text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500'
                                onClick={() => navigate(-1)}>Cancel
                            </button>
                            <button type='submit' disabled={filteredDisciplines.length === 0}
                                className={twMerge("absolute right-0 text-white ", filteredDisciplines.length !== 0 ?
                                    "bg-orange-1000 dark:text-gray-800 dark:bg-orange-1000" :
                                    "text-gray-900 bg-gray-500 dark:text-gray-900 dark:bg-gray-500")}>
                                Submit
                            </button>
                        </div>
                        <div className='flex items-center justify-center mt-10 p-12 '>
                            <PiSealWarning className='mr-2 text-uniteOrange' />
                            <h2>In order to add a unit checklist item, you must be connected to the internet.
                            </h2>
                        </div>
                    </form>
                </div>
            </div>
    );
}
